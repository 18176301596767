import React from 'react'
import Nav from '../Nav'
import { Animate, Card, Table, useAPI, useView } from 'components/lib'

export default function VerificationRequests() {
    const { data, loading } = useAPI('/api/marketing/verification_requests')
    const view = useView()

    const deleteVerificationRequest = (data, callback) => {
        view.modal.show({
            title: 'Delete request',
            form: {},
            buttonText: 'Delete',
            text: `Are you sure you want to delete ${data.firstname}'s verification request?`,
            url: `/api/marketing/verification_requests/${data.id}`,
            method: 'DELETE',
            destructive: true,
        }, () => {
            view.notification.show(`${data.firstname}'s request was deleted`, 'success', true);
            callback();
        });
    }

    return (
        <>
            <Nav />

            <Animate>
                <Card loading={loading}>
                    <Table
                        search
                        show={['business_name', 'email', 'business_address', 'account_status', 'created_at']}
                        loading={loading}
                        data={data?.verification_requests}
                        badges={['account_status']}
                        badge={[
                            {
                                col: 'account_status', color: 'red', condition: [
                                    { value: 'Created', color: 'green' }
                                ]
                            }
                        ]}
                        actions={{
                            view: { url: '/marketing/verification_requests', col: 'id' },
                            delete: deleteVerificationRequest
                        }}
                    />
                </Card>
            </Animate>
        </>
    )
}