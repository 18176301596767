import { useContext } from 'react';
import Axios from 'axios';
import { ViewContext, Card, Table, Animate, useAPI, useNavigate, Button } from 'components/lib';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

export function Users(props) {

  const [searchParams] = useSearchParams()
  const b = searchParams.get('b')
  const t = searchParams.get('t')

  const viewContext = useContext(ViewContext);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['user'],
    queryFn: (data) => Axios.get('/api/user', data).then(res => res.data),
  })

  const navigate = useNavigate()

  const editUser = (d) => {
    viewContext.modal.show({
      title: 'Edit User',
      form: {
        firstname: {
          label: 'Firstname',
          type: 'text',
          value: d.firstname,
          required: true,
        },
        lastname: {
          label: 'Lastname',
          type: 'text',
          value: d.lastname,
          required: true,
        },
        email: {
          label: 'Email',
          type: 'email',
          value: d.email,
          required: true
        },
        phonenumber: {
          label: 'Phone Number',
          type: 'phone',
          value: d.phonenumber,
          required: true,
        },
        business_name: {
          label: 'Business Name',
          type: 'text',
          value: d.business_name,
          required: true,
        },
        business_address: {
          label: 'Business Address',
          type: 'text',
          value: d.business_address,
          required: true,
        },
        business_phonenumber: {
          label: 'Verified Phone Number',
          type: 'phone',
          value: d.business_phonenumber,
          required: true,
        },
        business_category: {
          label: 'Business Category',
          type: 'text',
          value: d.business_category,
          required: true,
        },
        status: {
          label: 'Status',
          type: 'select',
          default: d.o_status,
          caption: 'When suspending an account owner all the other child accounts cannot login until the owner is verified again.',
          options: [
            { label: 'Unverified', value: 'unverified', checked: true },
            { label: 'Verified', value: 'verified' },
            { label: 'Inactive', value: 'inactive' },
            { label: 'Suspended', value: 'suspended' }
          ],
          required: true,
          containerClass: 'col-span-2'
        },
        ...['master', 'owner'].includes(d.o_permission) && {
          business_verified: {
            type: 'checkbox',
            option: 'Business Verified',
            defaultChecked: d.business_verified,
          }
        },
        oldEmail: {
          value: d.email,
          type: 'hidden'
        }
      },
      inputsClassName: 'grid sm:grid-cols-2 gap-2',
      buttonText: 'Save',
      url: `/api/user/${d.id}`,
      method: 'PATCH'

    }, () => {
      viewContext.notification.show(d.name + ' was updated', 'success', true);
      refetch()
    });
  }

  const deleteUser = (d, callback) => {
    viewContext.modal.show({
      title: 'Delete User',
      form: {},
      buttonText: 'Delete User',
      text: `Are you sure you want to delete ${d.email}? This will cancel any active campaigns for this user.`,
      url: `/api/user/${d.id}`,
      method: 'DELETE',
      destructive: true,

    }, () => {

      viewContext.notification.show(`${d.email} was deleted`, 'success', true);
      callback();

    });
  }

  const impersonateUser = async (d) => {

    try {

      const res = await Axios.post(`/api/user/impersonate/${d.id}`);
      const token = res.data?.data?.token;
      if (token) {
        window.location = process.env.REACT_APP_REMOTE_CLIENT + `/signin/impersonate?token=${token}`
      }
    }
    catch (err) {
      viewContext.handleError(err);
    }
  }

  const createUser = (d) => {
    viewContext.modal.show({
      title: 'Create User (business owner)',
      form: {
        firstname: {
          label: 'Firstname',
          type: 'text',
          value: d.firstname,
          required: true,
        },
        lastname: {
          label: 'Lastname',
          type: 'text',
          value: d.lastname,
          required: true,
        },
        email: {
          label: 'Email',
          type: 'email',
          value: d.email,
          required: true
        },
        phonenumber: {
          label: 'Phone Number',
          type: 'phone',
          value: d.phonenumber,
          required: true,
        },
        business_name: {
          label: 'Business Name',
          type: 'text',
          value: d.business_name,
          required: true,
        },
        business_address: {
          label: 'Business Address',
          type: 'text',
          value: d.business_address,
          required: true,
        },
        business_phonenumber: {
          label: 'Verified Phone Number',
          type: 'phone',
          value: d.business_phonenumber,
          required: true,
        },
        business_category: {
          label: 'Business Category',
          type: 'text',
          value: '',
          required: true,
        },
        password: {
          label: 'Password',
          type: 'password',
          required: true,
          containerClass: 'col-span-2'
        },
        verified: {
          type: 'checkbox',
          option: 'Email Verified',
          defaultChecked: false,
          containerClass: 'col-span-2'
        },
        business_verified: {
          type: 'checkbox',
          option: 'Business Verified',
          defaultChecked: false,
          containerClass: 'col-span-2'
        }
      },
      inputsClassName: 'grid sm:grid-cols-2 gap-2',
      buttonText: 'Create',
      url: `/api/user`,
      method: 'POST'

    }, () => {
      viewContext.notification.show('User was created', 'success', true);
      window.location.replace('/users')
    });
  }

  const verificationRequest = useMutation({
    mutationKey: ['verification_requests', b],
    mutationFn: () => Axios.get(`/api/marketing/verification_requests/${b}`).then(res => res.data)
  })

  useEffect(() => {
    const handle = async () => {
      const vRes = await verificationRequest.mutateAsync();
      const vData = vRes?.data;
      if (vData) {
        if (t === 'verify') {
          const user = data.data.find(u => u.id === vData.user_id)
          editUser(user)
        }
        if (t === 'create') {
          createUser(vData)
        }
      }
    };

    if (b && data?.data) {
      handle();
    }
  }, [b, t, data]);

  return (
    <Animate>
      <Card>
        <div className='flex justify-end mb-4'>
          <Button
            text='Create User'
            small
            action={() => createUser({})}
          />
        </div>
        <Table
          search
          data={data?.data}
          loading={isLoading}
          show={["firstname", "lastname", "email", 'status', 'permission', "verified_phone_number", "active_campaigns", "total_campaigns", "date_created", "last_active"]}
          badges={['status', 'permission']}
          badge={[
            {
              col: 'status', color: 'gray', condition: [
                { value: 'unverified', color: 'gray' },
                { value: 'Email Verified', color: 'yellow' },
                { value: 'Fully Verified', color: 'green' },
                { value: 'suspended', color: 'red' },
              ]
            },
            {
              col: 'permission', color: 'gray', condition: [
                { value: 'Account Owner', color: 'green' },
                { value: 'Added admin', color: 'blue' },
                { value: 'Added user', color: 'orange' },
              ]
            },
          ]}
          actions={{
            edit: editUser,
            delete: deleteUser,
            email: true,
            custom: [
              { icon: 'log-in', action: impersonateUser },
              { icon: 'radio', action: (d) => navigate(`/campaigns/user/${d.id}`), title: 'Info & Campaigns' },
            ]
          }}
        />
      </Card>
    </Animate>
  );
}
