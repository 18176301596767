import { SubNav } from 'components/lib'
import React from 'react'

function Nav() {
    return (
        <SubNav items={[
            { link: '/marketing/leads', label: 'Leads' },
            { link: '/marketing/verification_requests', label: 'Verification Requests' }
        ]}
        />
    )
}

export default Nav