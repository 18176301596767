import { Animate, Button, Card, useAPI } from "components/lib"
import { Link, useParams } from "react-router-dom"

export default function VerificationRequest() {
    const { id } = useParams()
    const { data, loading } = useAPI(`/api/marketing/verification_requests/${id}`)


    return (
        <Animate>
            <Card loading={loading}>
                {
                    data?.id && (
                        <div>
                            <h1 className="text-3xl font-bold">{data.firstname} {data.lastname}</h1>
                            <span>{data?.created_at}</span>

                            <div className="mt-6">
                                <h3 className="text-lg font-semibold">User Details</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-4">First Name:</td>
                                            <td>{data?.firstname}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4">Last Name:</td>
                                            <td>{data?.lastname}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4">Email:</td>
                                            <td>{data?.email}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4">Phone:</td>
                                            <td>{data?.phonenumber}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4">Account Status:</td>
                                            <td>
                                                {
                                                    data?.user_id
                                                        ? <>
                                                            {'Created | '}
                                                            <Link to={`/users?b=${data.id}&t=verify`} className="underline text-blue-500">Make business verified</Link>
                                                        </>
                                                        : <>
                                                            {'Guest | '}
                                                            <Link to={`/users?b=${data.id}&t=create`} className="underline text-blue-500">Create Account</Link>
                                                        </>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="mt-6">
                                <h3 className="text-lg font-semibold">Business Details</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="pr-4">Business Name:</td>
                                            <td>{data?.business_name}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4">Business Phone:</td>
                                            <td className="pr-4">{data?.business_phonenumber}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4">Business Address:</td>
                                            <td>{data?.business_address}</td>
                                        </tr>
                                        <tr>
                                            <td className="pr-4">Additional Info:</td>
                                            <td>{data?.info}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }
            </Card>
        </Animate>
    )

}